/* eslint-disable import/no-anonymous-default-export */
export default {
    //login
    LETS_FIND: 'Now we will find the business that suits you best',
    BTN_INVESTOR: 'Investor',
    BTN_BUSINESS: 'Business',
    HELLO: 'Hello',
    FILL_FORM: "Please fill out the form below to get started",
    HEAD_ENTER_PHONE: 'Phone Number',
    PLACEHOLDER_ENTER_PHONE: 'Enter a phone number',
    INDIVIDUAL: 'Individual',
    COMPANY: 'Comapny',
    LOGIN_BTN_TXT: 'Login',
    EMAIL: 'Enter Email',
    PLC_EMAIL: 'Email',
    PASS: 'Enter Password',
    PLH_PASS: 'Password',
    HV_ACCOUNT: `Don't have account`,
    SIGN_UP: 'Sign up',
    MAN: 'Man',
    FORGOT_PASS: 'Forgot Password',
    CHOOSE_TYPE: "Choose Account Type",
    ALREAD_ACC: "Already have an account",
    SIGN_IN: "Sign In",
    Investors: "Investors",
    Favorite_Investor: "Favorite Investor",
    Favorite_Investor_List: "Favorite Investor List",
    // //////////Code Screen
    CODE_IN_SMS: 'Code in sms',
    CODE_SENT_STRING: 'We have sent you a one-time mobile code',
    CODE_PLACEHOLDER: 'Enter a code',
    CODE_BTN: 'Approval',

    // ///////// User Photo
    USER_PICTURE: 'User picture',
    my_Profile: 'My Profile',
    PLEASE_UPLOAD: "Please upload a photo to verify the user",
    ADD_PHOTO: 'Add a picture',
    ADD_PROFILE_IMG: 'Add a picture',
    BTN_NEXT: 'Next',

    // ///////// Identity Information
    HEAD_IDENTITY: 'Identifying information',
    DESC_IDENTITY: 'Enter ID information',
    TITLE_IDENTITY: 'ID document',
    DETAIL_IDENTITY: 'Enter your social security number and photo ID for security purposes.',
    BTN_IDENTITY: 'Identity',
    BTN_ID_NUMBER: 'ID number',
    FRONT: 'Front shot',
    BACK: 'Back shot',
    PLACE_ID_NUMBER: 'Enter your ID number',

    // ///////////////////Personal Information
    HEAD_P_INFO: 'Personal Information',
    DESC_P_INFO: 'Please enter your personal details',
    SKIP: 'Skip',
    TITLE_FIRST_NAME: 'First Name',
    PLACE_FIRST_NAME: 'Enter your first name',
    TITLE_LAST_NAME: 'Last Name',
    PLACE_LAST_NAME: 'Enter your last name',
    TITLE_EMAIL: 'Email',
    PLACE_EMAIL: 'Enter your Email',
    TITLE_PASS: 'Password',
    PLACE_PASS: 'Enter your Password',
    CON_PASS: "Confirm Password",
    TITLE_GENDER: 'Gender',
    PLACE_GENDER: 'Select gender',
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
    CURRENCY_TYPE: 'Currency Type',
    PLH_CURRENCY_TYPE: 'Select Currency',
    BTN_SUBMIT: 'Submit',

    // ////////////// Code in Email
    HEAD_CODE_EMAIL: 'Code in email',
    DESC_CODE_EMAIL: 'We sent you a one-time code by email',

    // /////////////// Home Screen
    SELLING_BUSINESS: 'Selling business',
    INVESTING_BUSINESS: 'Investment in business',
    INVESTOR_LIST: 'Investor List',
    CONTRACTS: 'Contracts',
    INVESTMENTS: 'Investments',
    Fav_Bus: "Favourite Business",
    // /////////// SIDE BAR
    HOME: 'Main Menu',
    SUPPORT: 'support',
    TERM_AND_COND: 'Terms and Conditions',
    PROFILE: 'profile',
    LOGOUT: 'Disconnected',
    CREATE_BUSINESS: 'Create a new business',
    NDA_CONTRACT: 'NDA Contract',
    Bus_CONTRACT: 'Startup Contract',
    My_bus: "My Business",
    Logout: "Logout",
    Bus_create: "Create Business",
    Investor_Detail: "Investor Detail",
    Company_Description: "Company Description",
    Detail_of_Investor: "Detail of Investor",
    Name: "Name",
    Invested_Amount: "Invested Amount",
    Company_Name: "Company Name",
    Min_Invest_Am: "Min Invested Amount",
    Max_Invest_Am: "Max Invested Amount",
    Select_Bus: "Please Select Your Business",
    Home: "Home",
    About_us: "About us",
    Pricing: "Pricing",
    Contact: "Contact",
    Entrepreneur:"Entrepreneur",

    // ///////////// Detail Page
    HEAD_D_PAGE: 'Details',
    DESC_D_PAGE: 'Buying businesses',
    ADVANCE_SEARCH: 'Advanced Search',
    BTN_VIEW_AD: 'View Ad',
    Choose_Type: "Choose Type",
    Search: "Search",
    Clear: "Clear",
    Business_Type: "Startup Type",
    Accept: "Accept",
    Terms_Condition: "Term & Conditions",
    Chat: "Chat",
    Already_bus_created: "This business has already been associated with this user. Please choose a different business",
    Apply_Contract: "Apply Contract",
    Investor_Signatures: "Investor Signatures",
    Business_Owner_Signatures: "Startup Owner Signatures",
    Investment_Agreement: "Investment Agreement",
    Sign_Terms: "Signing the terms with a form that completes the detail of the parties",

    // /////////// Product Detail
    RAISED_AMOUNT: 'Amount Raised',
    LOCATION: 'location',
    SINCE: 'Since',
    BUSINESS_DESC: 'Business Description',
    FINANCIAL_INFO: 'Finance information',
    OBLIGATION_RES: 'Obligations and Restrictions',
    BTN_CONTACT_US: 'Contact us',
    PRICE: 'price',
    Bus_process: "The business process is managed from the perspective of the business itself.",

    // ////////////// Contracts
    HEAD_CONTRACTS: 'Initial contracts',
    CONTRACTS_DETAIL: 'A contract between an investor and the startup owner',
    BTN_ONGOING: 'Pending Contract',
    BTN_COMPLETED: 'Completed Contract',
    BTN_VIEW_CONTRACT: 'Show contract',
    ALREADY_CREATED: 'You already created a startup contract for this business',
    VIEW_CONTRACT: 'View Contract',
    VIEW_BUSINESS: 'View Startup',
    CREATE_BUS_CONTRACT: "Startup Contract",
    optional: "optional",

    // //////////// Signing Contracts
    DETAIL_S_CONTRACT: 'Signing the terms in the form that completes the details of the parties',
    BUSINESS_OWNER_SIGNATURE: 'Signature of the startup owner',
    INVESTOR_SIGNATURE: 'The investor signature',
    CHAT_OWNER: 'Chat with the startup owner',
    SEND_OWNER: 'Send to the startup owner',
    SIGNATURE: 'signature',
    DIGITAL_SIGNATURE: 'digital signature',
    UPLOAD_MOBILE: 'Upload from mobile',
    Add_your_Signature: "Add your Signature",

    // //////////// Create Business
    HEAD_C_BUSINESS: 'Create a Startup',
    DESC_C_BUSINESS: 'You can create your startup here',
    BUSINESS_INFORMATION: 'Startup information',
    TITLE_B_NAME: 'Startup name',
    PLACE_B_NAME: 'Type the name of the startup',
    PLACE_LOCATION: 'Enter a location',
    PLACE_SINCE: 'Since what year has the startup been active?',
    TITLE_B_TYPE: 'Type of startup',
    PLACE_B_TYPE: 'Choose type',
    TITLE_B_STATUS: 'Startup status',
    PLACE_B_STATUS: 'Select Status',
    TITLE_REQ_AMOUNT: 'The amount requested to complete the recruitment',
    PLACE_REQ_AMOUNT: 'come in',
    TITLE_DESC: 'Description',
    PLACE_DESC: 'Enter the description',
    Business_Images: "Startup Images",
    Diligence: "Due Diligence",
    Legal_Diligence: "Legal Diligence",
    Funding_Amount: "Funding Amount",
    Business_Diligence: "Startup Diligence",
    Financial_Statement: "Financial Statement",
    Business_Certificate: "Startup Certificate",
    Other_Relevant_Document: "Other Relevant Document",
    TITLE_BUSINESS_CER: 'Startup verification documents',
    CompanyName: "Company Name",
    CompanyAddress: "Company Address",
    CompanyNumber: "Company Number",
    Address: "Address",
    NDA_Confidentiality_Agreement: "NDA Confidentiality Agreement",
    NDA_Confidentiality_Agreement_Detail: "A legal contract outlining confidentiality obligations between parties, typically used to protect sensitive information.",
    Business_Information: "Startup Information",
    Professional_Plan: "Professional Plan",
    investor_profile: "You can see investor profile here",
    Messages: "Messages",
    // /////////////////// TOAST

    T_ENTER_CODE_SMS: 'Please enter the code that sends you through sms',
    T_CHECK_CODE_SMS: 'Please enter the correct code that sends you through sms',
    T_ENTER_PHONE_V: 'Please enter valid phone number',
    BTN_VIEW_BUSINESS: 'View Business',
    Business_Man: "Business Man",
    Investment_Ideas: "Investment Ideas",
    Business_Ideas: "Business Ideas",
    No_Investor_Found: "No Investor Found",
    ///////////////////// Terms and Conditions ////////////////////////////////////////
    Terms_Condition_Business: "I confirm that every transaction executed will be paid to Globasity a Finder commission",
    ////////////// pages content//////////////////////////////
    ///////////// Homepage //////////////////////////////////
    Headline: "A New World of Investing for Small Businesses and Investors",
    Headline2: "Revolutionizing Small Business and Investor Engagement",
    Subheadline: "Connecting small startup owners and private investors for smarter financing and investment opportunities.",
    Paragraph1: "Globasity is an AI-powered platform that makes financing easier for small startup owners and opens up new investment opportunities for individual investors. We provide the connections and tools needed to grow businesses and investment portfolios.",
    Paragraph2: "For small startup owners, we offer an alternative way to obtain funding that looks beyond traditional criteria. Connect with individual investors in your community willing to finance credit-challenged entrepreneurs. For investors, we provide access to a diverse array of investments in real small businesses right in your area. ",
    Paragraph3: "On our intelligent platform, you can quickly and easily:",
    Para3_Point1: "As a startup owner, create a profile showcasing your business to prospective investors. Upload financials, share your story and growth goals, and specify the funding you need.",
    Para3_Point2: "As an investor, browse businesses looking for financing and invest across multiple opportunities. Review business profiles and financials to make informed investment choices based on your risk tolerance and interests.",
    Para3_Point3: "- For both sides, our AI-powered algorithms will recommend tailored matches based on your criteria. Our team of experts provides due diligence and handles contracts, security, and payments.",
    Paragraph4: "Join the new world of smarter investing and business financing today. We provide the tools, connections, and expertise to help small businesses grow and investors diversify.",
    Smarter: "Smarter Investing & Financing",
    How_Works: "How it works?",
    Read_more: "Read More",
    Get_Started: "Get Started",
    Premium: "Premium Partnership Plans",
    Contract: "Contract",
    Business_Man_Subcription: "Business List Subcription",
    Investor_Payment: "Investor Payment on per Contract",
    Revolutionizing_Financing: "Globasity: Revolutionizing Financing and Investment Opportunities",
    Insights: "Insights",
    Research: "Research",
    Identity: "Identity",
    Insights_detail: "Globasity's core mission is to provide connections and tools that empower businesses to grow and help investors diversify their portfolios.",
    Research_detail: " Six years of research focused on streamlining small business financing and expanding investment opportunities for individuals.",
    Identity_detail: "Globasity is an AI-powered platform designed to facilitate financing for small businesses and offer new investment prospects for individual investors.",
    ///////////// About us //////////////////////////////////
    About_Headline: "Financing Small Businesses Smarter Investing",
    About_Para3_Point1: "startup owners create a profile showing off their business to prospective investors. Upload financials, share your story and growth goals, and specify the funding you need.",
    About_Para3_Point2: "Investors browse businesses looking for financing and build a diversified portfolio. Review profiles and financials to make informed investment choices based on your criteria",
    About_Para3_Point3: "Our intelligent algorithms recommend tailored financing matches based on your needs and preferences.",
    About_Para3_Point4: "Our team of experts provides due diligence on each business, draws up investment contracts, handles security and payments, and supports you each step of the way.",
    About_Paragraph4: "Globasity offers an easier way for small startup owners to secure financing to start or grow their company. For investors, we provide access to a whole new asset class of diverse small business investment opportunities right in your community. Join us and be part of the new world of smarter investing and business financing.",
    Six_Year: "Six Years of In-Depth Research",
    Audience: "Audience segmentation",
    Contact_monitoring: "Contact monitoring",
    Our_Mission: "Our Mission",
    Our_Vission: "Our Vission",
    View_Pricing: "View Pricing",
    OUR_STORY: "Our Story",

    About_Paragraph1: "Globasity was founded to transform how small businesses get the funding they need to grow and thrive. Our revolutionary fintech platform connects businesses with investors in a streamlined, transparent process.",
    About_Paragraph2: "Our mission is to level the playing field so that all small businesses have equal access to financing, regardless of their credit rating or stage of growth. Traditional lenders reject countless viable businesses every year. Meanwhile, individual investors struggle to find promising private investment opportunities.",
    About_Paragraph3: "Globasity solves both problems by bringing together startup owners and investors on a trusted digital platform.",
    Our_Values: "Our Values",
    three_value: "Globasity succeeds when small businesses succeed. We live by three core values",
    Accessibility: "Accessibility",
    Accessibility_content: "Providing equal access to financing for all qualified businesses",
    Transparency: "Transparency",
    Transparency_content: "Building trust through open communication and information sharing",
    Support: "Support",
    Support_content: "Delivering unmatched support and guidance every step of the way",
    How_It_Works: "How It Works",
    how_content: "Globasity uses technology to simplify every step of the private investment process.",
    For_Businesses: "For Businesses",
    b1: "Create a company profile showcasing your business to investors",
    b2: "Request funding and go through Globasity’s vetting process",
    b3: "Get matched with interested investors based on your criteria",
    b4: "Receive support in handling due diligence and closing the funding round",
    For_Investors: "For Investors",
    In1: "Browse pre-vetted small business investment opportunities",
    In2: "Evaluate and shortlist businesses using tools like search and filtering",
    In3: "Perform due diligence with materials prepared by Globasity",
    In4: "Invest in businesses you select and track performance",

    Meet_Our_Team: "Meet Our Team",
    meet_team_content1: "Our diverse team shares a passion for helping overlooked and undervalued small businesses unlock their full potential. We bring decades of combined experience scaling startups, developing financial platforms, managing investments, and running small businesses.",
    meet_team_content2: "Let us know if you have any other questions about Globasity's origin story and mission to democratize small business funding!",
    ////////////////////// Pricing ///////////////////////////////
    Pick_Plan: "Pick a plan that's right for you",
    Pricing_plan: "Pricing plans for businesses at every stage of growth.",
    FAQ: "Frequently Asked Questions",
    Month_sub: "per month subscription",
    Per_contract: "per contract",
    Choose_Plan: "Choose Plan",
    month: "month",

    ////////////contact/////////////////
    work_together: "We'd love to talk about how we can work together.",
    corporate: " Interested to learn more about how Globasity can help you secure funding for your small business or diversify your investments? Reach out to us!",
    Get_in_Touch: "Get in Touch",
    Title: "Title",
    Enter_Title: "Enter Title",
    help: "How can we help you?",
    message: "Send Message",

    ////////////////////////////////////footer/////////////////////
    terms: "Terms",
    Privacy: "Privacy",
    Condition: "Conditions",
    Startups: "Startups",
    No_Contract_Found: "No Contract Found",
    about_invest: "thinking about investing in this business?    ",
    hey: "Hey",
    Close: "Close",
    //////////////////////FAq's//////////////////////////////
    Q1: `What is "A New World of Investing for Small Businesses and Investors"?`,
    ANS1: "This is a platform that connects small startup owners with private investors to facilitate smarter financing and investment opportunities.",
    Q2: "How does this platform benefit small businesses?",
    ANS2: "Small businesses can access capital from private investors, helping them grow and expand their operations.",
    Q3: "How does this platform benefit private investors?",
    ANS3: "Private investors can discover new investment opportunities in small businesses, potentially generating higher returns than traditional investments.",
    Q4: "What types of businesses can use this platform?",
    ANS4: "We welcome a wide range of small businesses, including startups, established companies, and those seeking growth capital.",


    ////////////////////////// Terms & Condition/////////////////////////
    terms1: "Businesses must meet eligibility criteria including being located in supported countries and requesting funding under $10 million USD.Other terms may apply.",
    terms2: "Businesses are responsible for providing accurate information during the submission process.Misrepresentation could result in removal from the platform.",
    terms3: "Globasity takes a small percentage commission on successful funding rounds(% 7.5).Terms are outlined before finalizing any investment deal.",
    terms4: "Investors must meet accreditation standards and minimum investment amounts may be required.",
    terms5: "Globasity runs due diligence on all businesses listed on the platform but does not guarantee the performance of any investment.Investments have risks.",
    ////////////////////////////////////Commercial Terms/////////////////////////////////////
    Commercial_Terms: "Commercial Terms",
    com_des: "Please accept the commercial terms and pay the amount of subscription",
    com1: "Usage License Fees: Globasity charges businesses a small commission (% 7.5) on successful funding rounds.",
    com2: "Investor Subscription Fees: Access to the platform will be charged $49.99/month for premium plans.",
    com3: "Payment Terms: Payments by credit card. Subscriptions auto-renew monthly. No long-term commitments.",
    com4: "Service Guarantees: Globasity will provide maximum exposure and investor matching services. We do not guarantee any business's performance.",
    accept_btn: "I accept the commercial terms",
    Subscribe: "Subscribe",
    ///////////////////////////////////////////FAQs//////////////////////////////////
    ////////////////////////////////////Pro Plans////////////////////////////////////////////
    pro1: "All features of the Basic Plan",
    pro2: "Unlimited deal flow emails",
    pro3: "Advanced search and filters",
    pro4: "Portfolio performance tracking",
    pro5: "Email and phone support",
    ///////////////////////////////////Investment Information///////////////////////////////////
    Investment_Information: "Investment Information",
    enter_investment: " Please Enter Your Investment Information",
    already_invested: "Already investment amount",
    current_invested: 'Current investing amount',
    min_amount: "Min Amount",
    max_amount: "Max Amount",


    /////////////////////////////Subscription//////////////////////////////////
    Subscription: "Subscription",
    Subscription_desc: "Globasity offers tiered subscription plans for investors",
    Subscription_head: "Business user can subscribe thier package",
    basic: "Basic (Free) - Browse 5 featured businesses per month",
    Premium_sub: "Premium ($49.99/month) - All lower tier perks plus deal flow alerts, analytics, reporting",
    //////////////////////////////////
    No_Business_Found: "No Startups Found",
    Load_More: "Load More",

    ///forgot
    Email_Verification: "Email Verification",
    send_code: "Check your email, get a code we send you",
    send_code_bnt: "Send Code",
    ///
    code_email: "Code in Email",
    send_code2: "We sent a you a one-time email code",
    enter_code: "Enter Code",
    Approval: "Approval",
    ////
    Change_Password: "Change Password",
    enter_new_pass_des: "Enter a new password , confirm it and go ahead",
    Enter_New_Password:"Enter New Password",
}