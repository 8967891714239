/* eslint-disable import/no-anonymous-default-export */
export default {
    LETS_FIND: 'כעת נמצא את העסק שהכי מתאים לך',
    BTN_INVESTOR: 'משקיע',
    BTN_BUSINESS: 'עסק',
    HELLO: 'שלום',
    FILL_FORM: "אנא מלא את הטופס למטה כדי להתחיל",
    HEAD_ENTER_PHONE: 'הזן מספר טלפון כדי להיכנס',
    PLACEHOLDER_ENTER_PHONE: 'הזן מספר טלפון',
    INDIVIDUAL: 'אדם פרטי',
    COMPANY: 'חברה',
    LOGIN_BTN_TXT: 'התחברות',
    EMAIL: 'הזן אימייל',
    PLC_EMAIL: 'אימייל',
    PASS: 'הזן את הסיסמה',
    PLH_PASS: 'סיסמה',
    HV_ACCOUNT: 'אין לך חשבון?',
    SIGN_UP: 'הירשם',
    MAN: 'איש',
    FORGOT_PASS: 'שכחת את הסיסמא',
    CHOOSE_TYPE: "בחר סוג חשבון",
    ALREAD_ACC: "כבר יש לך חשבון",
    SIGN_IN: "להתחבר",
    Business_Man: "איש עסקים",
    Favorite_Investor: "משקיע מועדף",
    Favorite_Investor_List: "רשימת משקיעים מועדפים",


    // //////////Code Screen
    CODE_IN_SMS: 'קוד ב-sms',
    CODE_SENT_STRING: 'שלחנו לך קוד חד פעמי לנייד',
    CODE_PLACEHOLDER: 'הזן קוד',
    CODE_BTN: 'אישור',

    // ///////// User Photo
    USER_PICTURE: 'תמונת משתמש',
    PLEASE_UPLOAD: "אנא העלה תמונה כדי לאמת את המשתמש",
    ADD_PHOTO: 'הוסף תמונה',
    ADD_PROFILE_IMG: 'הוסף את תמונת הפרופיל שלך',
    BTN_NEXT: 'הבא',

    // ///////// Identity Information
    HEAD_IDENTITY: 'מידע מזהה',
    DESC_IDENTITY: 'הזן מידע מזהה',
    TITLE_IDENTITY: 'מסמך מזהה',
    DETAIL_IDENTITY: 'הזן את מספר תעודת הזהות ותמונה מזהה שלך מטעמי אבטחה.',
    BTN_IDENTITY: 'זהות',
    BTN_ID_NUMBER: 'מספר זהות',
    FRONT: 'צילום קדמי',
    BACK: 'צילום אחורי',
    PLACE_ID_NUMBER: 'הזן את מספר הזהות שלך',

    // ///////////////////Personal Information
    HEAD_P_INFO: 'מידע אישי',
    DESC_P_INFO: 'אנא הכנס את הפרטים האישיים שלך',
    SKIP: 'דלג',
    TITLE_FIRST_NAME: 'שם פרטי',
    PLACE_FIRST_NAME: 'הזן את שמך הפרטי',
    TITLE_LAST_NAME: 'שם משפחה',
    PLACE_LAST_NAME: 'הזן שם משפחה',
    TITLE_EMAIL: 'אימייל',
    PLACE_EMAIL: 'הזן כתובת אימייל ',
    TITLE_PASS: 'סיסמה',
    PLACE_PASS: 'הכנס את הסיסמה שלך',
    CON_PASS: "אשר סיסמה",
    TITLE_GENDER: 'מין',
    PLACE_GENDER: 'בחר מין',
    MALE: 'זָכָר',
    FEMALE: 'נְקֵבָה',
    OTHER: 'אַחֵר',
    CURRENCY_TYPE: 'סוג המטבע',
    PLH_CURRENCY_TYPE: 'תבחר מדינה',
    BTN_SUBMIT: 'שלח',

    // ////////////// Code in Email
    HEAD_CODE_EMAIL: 'קוד במייל',
    DESC_CODE_EMAIL: 'שלחנו לך קוד חד פעמי במייל',

    // /////////////// Home Screen
    SELLING_BUSINESS: 'מכירת עסקים',
    INVESTING_BUSINESS: 'השקעה בעסקים',
    INVESTOR_LIST: 'רשימת משקיעים',
    CONTRACTS: 'חוזים',
    INVESTMENTS: 'השקעות',
    Fav_Bus: "עסק אהוב",

    // /////////// SIDE BAR
    HOME: 'תפריט ראשי',
    SUPPORT: 'תמיכה',
    TERM_AND_COND: 'תנאים והתניות',
    PROFILE: 'פרופיל',
    LOGOUT: 'התנתק',
    CREATE_BUSINESS: 'צור עסק חדש',
    NDA_CONTRACT: 'חוזי NDA',
    Bus_CONTRACT: 'חוזים עסקיים',
    My_bus: "העסק שלי",
    Logout: "להתנתק",
    Bus_create: "צור עסקים",
    Investor_Detail: "פרטי משקיע",
    Company_Description: "תיאור חברה",
    Detail_of_Investor: "פירוט המשקיע",
    Name: "שֵׁם",
    Invested_Amount: "סכום מושקע",
    Company_Name: "שם החברה",
    Min_Invest_Am: "סכום מושקע מינימלי",
    Max_Invest_Am: "סכום מושקע מקסימלי",
    Select_Bus: "אנא בחר את העסק שלך",
    Home: "בית",
    About_us: "עלינו",
    Pricing: "תמחור",
    Contact: "איש קשר",

    // ///////////// Detail Page
    HEAD_D_PAGE: 'פרטים',
    DESC_D_PAGE: 'קניית עסקים',
    ADVANCE_SEARCH: 'חיפוש מתקדם',
    BTN_VIEW_AD: 'צפה במודעה',
    Choose_Type: "בחר סוג",
    Search: "לחפש",
    Clear: "ברור",
    Business_Type: "טיפוס עסקי",
    Accept: "לְקַבֵּל",
    Chat: "לְשׂוֹחֵחַ",
    Terms_Condition: "תנאים והגבלות",
    Already_bus_created: "העסק הזה כבר משויך למשתמש הזה. אנא בחר עסק אחר",
    Apply_Contract: "החל חוזה",
    Business_Owner_Signatures: "חתימות בעלי עסקים",
    Investor_Signatures: "חתימות משקיעים",
    Sign_Terms: "חתימה על התנאים בטופס המשלים את פירוט הצדדים",
    Investment_Agreement: "הסכם השקעה",

    // /////////// Product Detail
    RAISED_AMOUNT: 'סכום שגויס',
    LOCATION: 'מיקום',
    SINCE: 'קיים משנת',
    BUSINESS_DESC: 'תיאור העסק',
    FINANCIAL_INFO: 'מידע פיננסי',
    OBLIGATION_RES: 'חובות והגבלות',
    BTN_CONTACT_US: 'צור קשר',
    PRICE: 'מחיר',
    Bus_process: "התהליך העסקי מנוהל מנקודת מבטו של העסק עצמו.",


    // ////////////// Contracts
    HEAD_CONTRACTS: 'חוזים ראשוניים',
    CONTRACTS_DETAIL: 'חוזה בין משקיע לבעל העסק',
    BTN_ONGOING: "חוזה תלוי ועומד",
    BTN_COMPLETED: 'חוזה שהושלם',
    BTN_VIEW_CONTRACT: 'הצג חוזה',
    ALREADY_CREATED: 'כבר יצרת חוזה עסקי עבור העסק הזה',
    VIEW_CONTRACT: 'צפה בחוזה',
    CREATE_BUS_CONTRACT: "יצירת חוזה עסקי",
    VIEW_BUSINESS: 'הצג עסקים',
    Add_your_Signature: "הוסף את החתימה שלך",
    optional: "אופציונאלי",
    // //////////// Signing Contracts
    DETAIL_S_CONTRACT: 'חתימה על התנאים בטופס המשלים את פרטי הצדדים',
    BUSINESS_OWNER_SIGNATURE: 'חתימת בעל העסק',
    INVESTOR_SIGNATURE: 'חתימת המשקיע',
    CHAT_OWNER: 'צאט עם בעל העסק',
    SEND_OWNER: 'שלח לבעל העסק',
    SIGNATURE: 'חתימה',
    DIGITAL_SIGNATURE: 'חתימה דיגיטלית',
    UPLOAD_MOBILE: 'העלה מהנייד',

    // //////////// Create Business
    HEAD_C_BUSINESS: 'צור עסק',
    DESC_C_BUSINESS: 'אתה יכול ליצור את העסק שלך כאן',
    BUSINESS_INFORMATION: 'מידע עסקי',
    TITLE_B_NAME: 'שם העסק',
    PLACE_B_NAME: 'הקלד את שם העסק',
    PLACE_LOCATION: 'הזן מיקום',
    PLACE_SINCE: 'מאיזה שנה העסק פעיל',
    TITLE_B_TYPE: 'סוג עסק',
    PLACE_B_TYPE: 'בחר סוג',
    TITLE_B_STATUS: 'מצב עסקי',
    PLACE_B_STATUS: 'בחר סטטוס',
    TITLE_REQ_AMOUNT: 'הסכום המבוקש להשלמת הגיוס',
    PLACE_REQ_AMOUNT: 'היכנס',
    TITLE_DESC: 'תיאור',
    PLACE_DESC: 'היכנס לתיאור',
    TITLE_BUSINESS_CER: 'מסמכי אימות עסק ומסמכים נוספים',
    Business_Images: "תמונות עסקיות",
    Diligence: "גילוי נאות",
    Funding_Amount: "סכום מימון",
    Legal_Diligence: "חריצות משפטית",
    Business_Diligence: "דוחות כספיים",
    Financial_Statement: "דוחות כספיים",
    Business_Certificate: "תעודת עסק",
    Other_Relevant_Document: "מסמך רלוונטי אחר",
    CompanyName: "שם החברה",
    CompanyAddress: "כתובת החברה",
    CompanyNumber: "מספר חברה",
    Address: "כתובת",
    NDA_Confidentiality_Agreement_Detail: "חוזה משפטי המתאר את חובות הסודיות בין הצדדים, המשמש בדרך כלל להגנה על מידע רגיש.",
    NDA_Confidentiality_Agreement: "הסכם סודיות הסכם סודיות",
    Business_Information: "מידע עסקי",
    investor_profile: "אתה יכול לראות את פרופיל המשקיעים כאן",
    Messages: "הודעות",

    //////////////////////////// Terms and Conditions//////////////
    Terms_Condition_Business: "אני מאשר שכל עסקה שתבוצע תשולם לגלובסיטי עמלת פינדר",

    // /////////////////// TOAST

    T_ENTER_CODE_SMS: 'Please enter the code that sends you through sms',
    T_CHECK_CODE_SMS: 'Please enter the correct code that sends you through sms',
    T_ENTER_PHONE_V: 'Please enter valid phone number',
    BTN_VIEW_BUSINESS: 'View Business',
    No_Investor_Found: "לא נמצא משקיע",
    ////////////// pages content//////////////////////////////
    ///////////// Homepage //////////////////////////////////
    Headline: "עולם חדש של השקעות לעסקים קטנים ולמשקיעים",
    Headline2: "מהפכה של עסקים קטנים ומעורבות משקיעים",
    Subheadline: "חיבור בין בעלי עסקים קטנים למשקיעים פרטיים להזדמנויות מימון והשקעה חכמות יותר.",
    Paragraph1: "Globasity היא פלטפורמה המונעת בינה מלאכותית המקלה על מימון לבעלי עסקים קטנים ופותחת הזדמנויות השקעה חדשות למשקיעים בודדים. אנו מספקים את הקשרים והכלים הדרושים להגדלת עסקים ותיקי השקעות.",
    Paragraph2: "לבעלי עסקים קטנים, אנו מציעים דרך חלופית להשיג מימון שנראה מעבר לקריטריונים מסורתיים. צור קשר עם משקיעים בודדים בקהילה שלך שמוכנים לממן יזמים בעלי אתגר אשראי. למשקיעים, אנו מספקים גישה למגוון מגוון של השקעות בעסקים קטנים אמיתיים באזור שלך.",
    Paragraph3: "בפלטפורמה החכמה שלנו, אתה יכול במהירות ובקלות:",
    Para3_Point1: "כבעל עסק, צור פרופיל המציג את העסק שלך למשקיעים פוטנציאליים. העלה מידע פיננסי, שתף את הסיפור ואת יעדי הצמיחה שלך וציין את המימון שאתה צריך.",
    Para3_Point2: "כמשקיע, עיין בעסקים המחפשים מימון והשקיע על פני הזדמנויות מרובות. סקור את הפרופילים העסקיים ואת הנתונים הפיננסיים כדי לבצע בחירות השקעה מושכלות בהתבסס על סובלנות הסיכון והאינטרסים שלך.",
    Para3_Point3: "- עבור שני הצדדים, האלגוריתמים המופעלים על ידי AI שלנו ימליצו על התאמות מותאמות בהתאם לקריטריונים שלך. צוות המומחים שלנו מספק בדיקת נאותות ומטפל בחוזים, אבטחה ותשלומים.",
    Paragraph4: "הצטרף לעולם החדש של השקעות חכמות יותר ומימון עסקי עוד היום. אנו מספקים את הכלים, הקשרים והמומחיות כדי לעזור לעסקים קטנים לצמוח ולגיוון המשקיעים.",
    Smarter: "השקעה ומימון חכמים יותר",
    How_Works: "איך זה עובד?",
    Read_more: "קרא עוד",
    Get_Started: "להתחיל",
    Premium: "תוכניות שותפות פרימיום",
    Contract: "חוֹזֶה",
    Business_Man_Subcription: "רשימת עסקים מנוי",
    Investor_Payment: "תשלום משקיע בכל חוזה",


    Revolutionizing_Financing: "מהפכה בהזדמנויות המימון וההשקעה",
    Insights: "תובנות",
    Research: "מחקר",
    Identity: "זהות",
    Insights_detail: "משימת הליבה של Globasity היא לספק קשרים וכלים המעצימים לעסקים לצמוח ולעזור למשקיעים לגוון את תיקי ההשקעות שלהם.",
    Research_detail: " שש שנים של מחקר התמקד בייעול מימון לעסקים קטנים והרחבת הזדמנויות השקעה לאנשים פרטיים.",
    Identity_detail: "Globasity היא פלטפורמה מונעת בינה מלאכותית שנועדה להקל על מימון לעסקים קטנים ולהציע סיכויי השקעה חדשים למשקיעים בודדים.",
    ///////////// About us //////////////////////////////////
    About_Headline: "מימון עסקים קטנים השקעה חכמה יותר",
    About_Para3_Point1: "בעלי עסקים יוצרים פרופיל המציג את העסק שלהם למשקיעים פוטנציאליים. העלה מידע פיננסי, שתף את הסיפור ואת יעדי הצמיחה שלך וציין את המימון שאתה צריך.",
    About_Para3_Point2: "משקיעים גולשים בעסקים המחפשים מימון ובונים תיק מגוון. סקור פרופילים ומידע פיננסי כדי לבצע בחירות השקעה מושכלות על סמך הקריטריונים שלך",
    About_Para3_Point3: "האלגוריתמים החכמים שלנו ממליצים על התאמות מימון מותאמות לפי הצרכים וההעדפות שלך.",
    About_Para3_Point4: "צוות המומחים שלנו מספק בדיקת נאותות בכל עסק, עורך חוזי השקעה, מטפל באבטחה ובתשלומים ותומך בך בכל שלב.",
    About_Paragraph4: "Globasity מציעה דרך קלה יותר לבעלי עסקים קטנים להשיג מימון כדי להקים או להצמיח את החברה שלהם. למשקיעים, אנו מספקים גישה לסוג נכסים חדש לגמרי של הזדמנויות השקעה מגוונות לעסקים קטנים ממש בקהילה שלך. הצטרפו אלינו והיו חלק מהעולם החדש של השקעות חכמות יותר ומימון עסקי.",
    Six_Year: "שש שנים של מחקר מעמיק",
    Audience: "פילוח קהל",
    Contact_monitoring: "ניטור יצירת קשר",
    Our_Mission: "ניטור יצירת קשר",
    Our_Vission: "החזון שלנו",
    View_Pricing: "צפה בתמחור",
    OUR_STORY: "הסיפור שלנו",
    Investors: "משקיעים",
    Professional_Plan: "תוכנית מקצועית",

    About_Paragraph1: "Globasity נוסדה כדי לשנות את האופן שבו עסקים קטנים מקבלים את המימון שהם צריכים כדי לצמוח ולשגשג. פלטפורמת הפינטק המהפכנית שלנו מחברת בין עסקים למשקיעים בתהליך יעיל ושקוף.",
    About_Paragraph2: "המשימה שלנו היא ליישר את מגרש המשחקים כך שלכל העסקים הקטנים תהיה גישה שווה למימון, ללא קשר לדירוג האשראי שלהם או לשלב הצמיחה שלהם. מלווים מסורתיים דוחים אינספור עסקים ברי קיימא מדי שנה. בינתיים, משקיעים בודדים נאבקים למצוא הזדמנויות השקעה פרטיות מבטיחות.",
    About_Paragraph3: "Globasity פותרת את שתי הבעיות על ידי חיבור בין בעלי עסקים ומשקיעים בפלטפורמה דיגיטלית מהימנה.",
    Our_Values: "הערכים שלנו",
    three_value: "גלובסיטי מצליחה כשעסקים קטנים מצליחים. אנו חיים לפי שלושה ערכי ליבה",
    Accessibility: "נְגִישׁוּת",
    Accessibility_content: "מתן גישה שווה למימון לכל העסקים המוסמכים",
    Transparency: "שְׁקִיפוּת",
    Transparency_content: "בניית אמון באמצעות תקשורת פתוחה ושיתוף מידע",
    Support: "תמיכה",
    Support_content: "מתן תמיכה והכוונה ללא תחרות בכל שלב של הדרך",
    How_It_Works: "איך זה עובד",
    how_content: "גלובסיטי משתמשת בטכנולוגיה כדי לפשט כל שלב בתהליך ההשקעה הפרטית.",
    For_Businesses: "לעסקים",
    b1: "צור פרופיל חברה המציג את העסק שלך למשקיעים",
    b2: "בקש מימון ועבור את תהליך הבדיקה של Globasity",
    b3: "קבל התאמה עם משקיעים מתעניינים בהתבסס על הקריטריונים שלך",
    b4: "",
    For_Investors: "למשקיעים",
    In1: "עיין בהזדמנויות השקעה לעסקים קטנים שנבדקו מראש",
    In2: "הערך והרשימה של עסקים באמצעות כלים כמו חיפוש וסינון",
    In3: "בצע בדיקת נאותות עם חומרים שהוכנו על ידי Globasity",
    In4: "השקיעו בעסקים שתבחרו ועקוב אחר ביצועים",
    Meet_Our_Team: "הכירו את הצוות שלנו",
    meet_team_content1: "הצוות המגוון שלנו חולק את התשוקה לעזור לעסקים קטנים שמתעלמים מהם ולא מוערכים בהם לנצל את מלוא הפוטנציאל שלהם. אנו מביאים עשרות שנים של ניסיון משולב בהגדלת סטארטאפים, פיתוח פלטפורמות פיננסיות, ניהול השקעות וניהול עסקים קטנים.",
    meet_team_content2: "ספר לנו אם יש לך שאלות נוספות על סיפור המקור של Globasity ומשימתה לדמוקרטיזציה של מימון לעסקים קטנים!",

    ////////////////////// Pricing ///////////////////////////////
    Pick_Plan: "בחר תוכנית שמתאימה לך",
    Pricing_plan: "תוכניות תמחור לעסקים בכל שלב של צמיחה.",
    FAQ: "שאלות נפוצות",
    Month_sub: "מנוי לחודש",
    Per_contract: "לכל חוזה",
    Choose_Plan: "בחר תוכנית",
    month: "חוֹדֶשׁ",

    ////////////contact/////////////////
    work_together: "נשמח לדבר על איך אנחנו יכולים לעבוד ביחד.",
    corporate: "מעוניין ללמוד עוד על האופן שבו Globasity יכולה לעזור לך להבטיח מימון לעסק הקטן שלך או לגוון את ההשקעות שלך? פנה אלינו!",
    Get_in_Touch: "נהיה בקשר",
    Title: "כותרת",
    Enter_Title: "הזן כותרת",
    help: "איך נוכל לעזור לך?",
    message: "לשלוח הודעה",
    Entrepreneur:"יזם",
    my_Profile: 'הפרופיל שלי',

    ////////////////////////////////////footer/////////////////////
    terms: "תנאים",
    Privacy: "פְּרָטִיוּת",
    Condition: "תנאים",
    Startups: "סטארט-אפים",
    No_Contract_Found: "לא נמצא חוזה",
    about_invest: "חושבים על השקעה בעסק הזה?",
    hey: "היי",
    Investment_Ideas: "רעיונות להשקעה",
    Business_Ideas: "רעיונות עסקיים",
    //////////////////////FAq's//////////////////////////////
    Q1: `מהו "עולם חדש של השקעות לעסקים קטנים ולמשקיעים"?`,
    ANS1: "זוהי פלטפורמה המחברת בין בעלי עסקים קטנים למשקיעים פרטיים כדי לאפשר מימון והשקעה חכמים יותר.",
    Q2: "כיצד הפלטפורמה הזו מועילה לעסקים קטנים?",
    ANS2: "עסקים קטנים יכולים לגשת להון ממשקיעים פרטיים, לעזור להם לצמוח ולהרחיב את פעילותם.",
    Q3: "כיצד הפלטפורמה הזו מועילה למשקיעים פרטיים?",
    ANS3: "משקיעים פרטיים יכולים לגלות הזדמנויות השקעה חדשות בעסקים קטנים, שעלולות להניב תשואות גבוהות יותר מהשקעות מסורתיות.",
    Q4: "אילו סוגי עסקים יכולים להשתמש בפלטפורמה הזו?",
    ANS4: "אנו מקדמים בברכה מגוון רחב של עסקים קטנים, כולל סטארט-אפים, חברות מבוססות וכאלה שמחפשים הון צמיחה.",
    ////////////////////////// Terms & Condition/////////////////////////
    terms1: "עסקים חייבים לעמוד בקריטריונים לזכאות, כולל הימצאות במדינות נתמכות ובקשת מימון מתחת ל-10 מיליון דולר. תנאים אחרים עשויים לחול.",
    terms2: "עסקים אחראים לספק מידע מדויק במהלך תהליך ההגשה. מצג שווא עלול לגרום להסרה מהפלטפורמה.",
    terms3: "גלובסיטי לוקחת עמלה קטנה באחוזים על סבבי גיוס מוצלחים (% 7.5). התנאים מפורטים לפני סיום עסקת השקעה כלשהי.",
    terms4: "המשקיעים חייבים לעמוד בתקני הסמכה וייתכן שיידרשו סכומי השקעה מינימליים.",
    terms5: "גלובסיטי מבצעת בדיקת נאותות בכל העסקים הרשומים בפלטפורמה אך אינה מבטיחה את הביצועים של השקעה כלשהי. להשקעות יש סיכונים.",
    ////////////////////////////////////Commercial Terms/////////////////////////////////////
    Commercial_Terms: "תנאים מסחריים",
    com_des: "נא לאשר את התנאים המסחריים ולשלם את סכום המנוי",
    com1: "דמי רישיון שימוש: גלובסיטי גובה מעסקים עמלה קטנה (% 7.5) על סבבי גיוס מוצלחים.",
    com2: "דמי מנוי למשקיעים: גישה לפלטפורמה תחויב ב-$49.99 לחודש עבור תוכניות פרימיום.",
    com3: "תנאי תשלום: תשלומים בכרטיס אשראי. מנויים מתחדשים אוטומטית מדי חודש. אין התחייבויות לטווח ארוך.",
    com4: "ערבויות שירות: גלובסיטי תספק שירותי חשיפה מרבית והתאמת משקיעים. אנחנו לא מבטיחים ביצועים של אף עסק.",
    accept_btn: "אני מקבל את התנאים המסחריים",
    Subscribe: "הירשם",
    ////////////////////////////////////Pro Plans////////////////////////////////////////////
    pro1: "כל התכונות של התוכנית הבסיסית",
    pro2: "אימיילים ללא הגבלה על זרימת עסקאות",
    pro3: "חיפוש מתקדם ומסננים",
    pro4: "מעקב אחר ביצועי תיק עבודות",
    pro5: "תמיכה במייל ובטלפון",
    ///////////////////////////////////Investment Information///////////////////////////////////
    Investment_Information: "מידע על השקעות",
    enter_investment: "אנא הזן את פרטי ההשקעה שלך",
    already_invested: "כבר סכום השקעה",
    current_invested: 'סכום השקעה נוכחי',
    min_amount: "כמות מינימלית",
    max_amount: "כמות מקסימלית",
    /////////////////////////////Subscription//////////////////////////////////
    Subscription: "מִנוּי",
    Subscription_desc: "גלובסיטי מציעה תוכניות מנוי מדורגות למשקיעים",
    Subscription_head: "משתמש עסקי יכול להירשם לחבילה שלו",
    basic: "בסיסי (חינם) - עיין ב-5 עסקים מומלצים בחודש",
    Premium_sub: "פרימיום ($49.99 לחודש) - כל ההטבות מהדרג התחתון בתוספת התראות על זרימת עסקאות, ניתוח, דיווח",
    //////////////////////////////////
    No_Business_Found: "לא נמצא עסק",
    Load_More: "טען עוד",
    ///forgot
    Email_Verification: "אימות אימייל",
    send_code: "בדוק את האימייל שלך, קבל קוד שנשלח לך",
    send_code_bnt: "שלח קוד",
    code_email: "קוד באימייל",
    send_code2: 'שלחנו לך קוד דוא"ל חד פעמי',
    enter_code: "להזין את הקוד",
    Approval: "הסכמה",
    Change_Password: "שנה סיסמא",
    enter_new_pass_des: "הזן סיסמה חדשה, אשר אותה והמשך",
    Enter_New_Password:"הכנס סיסמה חדשה",
}